/*@jsxRuntime classic @jsx React.createElement @jsxFrag React.Fragment*/
import {useMDXComponents as _provideComponents} from "@mdx-js/react";
import React from "react";
function _createMdxContent(props) {
  const _components = Object.assign({
    p: "p",
    a: "a",
    h2: "h2",
    div: "div",
    br: "br",
    h3: "h3",
    em: "em",
    strong: "strong"
  }, _provideComponents(), props.components), {HrefLangManager, TableOfContents, SideBySide, MapInfo, LandingPageCta, ThreeColCta} = _components;
  if (!HrefLangManager) _missingMdxReference("HrefLangManager", true);
  if (!LandingPageCta) _missingMdxReference("LandingPageCta", true);
  if (!MapInfo) _missingMdxReference("MapInfo", true);
  if (!SideBySide) _missingMdxReference("SideBySide", true);
  if (!TableOfContents) _missingMdxReference("TableOfContents", true);
  if (!ThreeColCta) _missingMdxReference("ThreeColCta", true);
  return React.createElement(React.Fragment, null, React.createElement(HrefLangManager, {
    alternates: [['x-default', 'https://www.hear.com/hearing-aids/accessories/'], ['en', 'https://www.hear.com/hearing-aids/accessories/'], ['en-US', 'https://www.hear.com/hearing-aids/accessories/'], ['en-CA', 'https://ca.hear.com/hearing-aids/accessories/']]
  }), "\n", React.createElement(TableOfContents, {
    tableOfContents: props.data.mdx.tableOfContents
  }), "\n", React.createElement(_components.p, {
    className: "c-md-p"
  }, "At hear.com, we want our customers to fully enjoy all that modern hearing aids have to offer. Hearing aids alone aren’t as powerful as they have the potential to be. It’s a good idea to know more about what accessories can do for you in order to really enjoy all of the ", React.createElement(_components.a, {
    href: "/hearing-aids/",
    className: "c-md-a"
  }, "hearing device’s features."), " Here’s a quick rundown of our main categories to help you make a decision."), "\n", React.createElement(_components.h2, {
    className: "c-md-h2",
    id: "get-the-best-reception-with-high-quality-hearing-aid-microphones",
    style: {
      position: "relative"
    }
  }, React.createElement(_components.a, {
    href: "#get-the-best-reception-with-high-quality-hearing-aid-microphones",
    "aria-label": "get the best reception with high quality hearing aid microphones permalink",
    className: "anchor before"
  }, React.createElement(_components.div, {
    dangerouslySetInnerHTML: {
      __html: "<svg aria-hidden=\"true\" focusable=\"false\" height=\"16\" version=\"1.1\" viewBox=\"0 0 16 16\" width=\"16\"><path fill-rule=\"evenodd\" d=\"M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z\"></path></svg>"
    }
  })), "Get the best reception with high-quality hearing aid microphones"), "\n", React.createElement(SideBySide, {
    imgSrc: "https://res.cloudinary.com/dhqvlsb3l/image/upload/f_auto,q_auto:best/v1/assets/na/seo-website/accessories-microphone.jpg"
  }, React.createElement(_components.p, {
    className: "c-md-p"
  }, "Firstly, you might want to think about purchasing a special microphone for your hearing aid. These tiny accessories are designed to clip onto clothing or to stand on solid surfaces. They amplify the sounds around them, sending the soundwaves straight to your hearing aid processor.\n", React.createElement(_components.br), "\n", React.createElement(_components.br), "\n", "They can be especially handy in business meetings where you need to hear every single word, as well as in social environments with high ambient noise levels. By adjusting the way your hearing aid receives sound, you may be really precise in selecting what you want to listen to. Other settings such as noise cancellation and volume level are also available.")), "\n", React.createElement(_components.p, {
    className: "c-md-p"
  }, "At hear.com, you’ll find a wide variety of leading brands’ microphones. Like Signia Voicelink, which is ideal for meetings and presentations where speakers move around the room."), "\n", React.createElement(_components.p, {
    className: "c-md-p"
  }, "You’ll experience clear and precise conversations to their full potential with the features special microphones have to offer."), "\n", React.createElement(MapInfo), "\n", React.createElement(_components.h2, {
    className: "c-md-h2",
    id: "make-changing-settings-easy-with-remote-controls",
    style: {
      position: "relative"
    }
  }, React.createElement(_components.a, {
    href: "#make-changing-settings-easy-with-remote-controls",
    "aria-label": "make changing settings easy with remote controls permalink",
    className: "anchor before"
  }, React.createElement(_components.div, {
    dangerouslySetInnerHTML: {
      __html: "<svg aria-hidden=\"true\" focusable=\"false\" height=\"16\" version=\"1.1\" viewBox=\"0 0 16 16\" width=\"16\"><path fill-rule=\"evenodd\" d=\"M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z\"></path></svg>"
    }
  })), "Make changing settings easy with remote controls"), "\n", React.createElement(SideBySide, {
    imgSrc: "https://res.cloudinary.com/dhqvlsb3l/image/upload/f_auto,q_auto:best/v1/assets/na/seo-website/accessories-remote-controls.jpg"
  }, React.createElement(_components.p, {
    className: "c-md-p"
  }, "Remote controls are another popular selection from our collection of accessories and most leading ", React.createElement(_components.a, {
    href: "/hearing-aids/brands/",
    className: "c-md-a"
  }, "hearing aid manufacturers"), " offer them in their options."), React.createElement(_components.p, {
    className: "c-md-p"
  }, "These are compact accessories that enhance the quality of standard hearing aids. In this case, they connect with other devices like smartphones, TVs or stereo systems, using wireless communications to stream content to your hearing aid. But that’s not all – most remote controls also include settings for managing hands-free phone calls as well as handy controls for adjustments (like volume and directional hearing). Remote controls fit into pockets or clip onto clothing with ease, putting users in full control of their hearing aids at all times.")), "\n", React.createElement(_components.p, {
    className: "c-md-p"
  }, "Popular options include the compact ConnectClip ", React.createElement(_components.a, {
    href: "/hearing-aids/brands/oticon/",
    className: "c-md-a"
  }, "from Oticon"), ", which doubles up as a microphone and Bluetooth controller, and EasyPocket ", React.createElement(_components.a, {
    href: "/hearing-aids/brands/signia/",
    className: "c-md-a"
  }, "from Signia"), ". With its larger dimensions, very clear display and plenty of ways to manage hearing aid settings, it is ideal for senior users."), "\n", React.createElement(_components.p, {
    className: "c-md-p"
  }, "There’s a handful of remote control options available of all major brands of hearing aids, so don’t hesitate to look for different models and find the one that fits your needs."), "\n", React.createElement(LandingPageCta, {
    copy: "Get the best hearing aid now",
    classes: "c-cta--content"
  }), "\n", React.createElement(_components.h2, {
    className: "c-md-h2",
    id: "enjoy-seamless-streaming-with-bluetooth-connections",
    style: {
      position: "relative"
    }
  }, React.createElement(_components.a, {
    href: "#enjoy-seamless-streaming-with-bluetooth-connections",
    "aria-label": "enjoy seamless streaming with bluetooth connections permalink",
    className: "anchor before"
  }, React.createElement(_components.div, {
    dangerouslySetInnerHTML: {
      __html: "<svg aria-hidden=\"true\" focusable=\"false\" height=\"16\" version=\"1.1\" viewBox=\"0 0 16 16\" width=\"16\"><path fill-rule=\"evenodd\" d=\"M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z\"></path></svg>"
    }
  })), "Enjoy seamless streaming with Bluetooth connections"), "\n", React.createElement(SideBySide, {
    imgSrc: "https://res.cloudinary.com/dhqvlsb3l/image/upload/f_auto,q_auto:best/v1/assets/na/seo-website/accessories-bluetooth.jpg"
  }, React.createElement(_components.p, {
    className: "c-md-p"
  }, "While some remote controls are optimized for streaming, there are also ", React.createElement(_components.a, {
    href: "/hearing-aids/technology/bluetooth/",
    className: "c-md-a"
  }, "Bluetooth connections"), " that offer a more advanced experience when it comes to ", React.createElement(_components.a, {
    href: "/hearing-aids/technology/",
    className: "c-md-a"
  }, "technology"), ". If you love to stream audio from TV, radio, computer or sound systems, these accessories are a must-have.\n", React.createElement(_components.br), "\n", React.createElement(_components.br), "\n", "For instance, Signia offer their EasyTek line of accessories, which pairs with all their cutting-edge wireless 3.0-enabled hearing aids. Worn around the neck (or stashed in a bag or pocket), EasyTek connects all of your Bluetooth devices and streams content directly to the hearing aids.")), "\n", React.createElement(_components.p, {
    className: "c-md-p"
  }, "Alternatively, you might take a look at ReSound’s Phone Clip+. Designed to be used with any Bluetooth-enabled smartphone, it also handles audio streaming, with a focus on ensuring that hearing aid users can hear telephone conversations clearly."), "\n", React.createElement(_components.p, {
    className: "c-md-p"
  }, "So, if you have trouble understanding phone conversations or you want to link together different devices, these Bluetooth connections are a great investment."), "\n", React.createElement(LandingPageCta, {
    copy: "Get the best hearing aid now",
    classes: "c-cta--content"
  }), "\n", React.createElement(_components.h2, {
    className: "c-md-h2",
    id: "hear-every-word-of-your-favorite-shows-with-tv-transmitters",
    style: {
      position: "relative"
    }
  }, React.createElement(_components.a, {
    href: "#hear-every-word-of-your-favorite-shows-with-tv-transmitters",
    "aria-label": "hear every word of your favorite shows with tv transmitters permalink",
    className: "anchor before"
  }, React.createElement(_components.div, {
    dangerouslySetInnerHTML: {
      __html: "<svg aria-hidden=\"true\" focusable=\"false\" height=\"16\" version=\"1.1\" viewBox=\"0 0 16 16\" width=\"16\"><path fill-rule=\"evenodd\" d=\"M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z\"></path></svg>"
    }
  })), "Hear every word of your favorite shows with TV transmitters"), "\n", React.createElement(SideBySide, {
    imgSrc: "https://res.cloudinary.com/dhqvlsb3l/image/upload/f_auto,q_auto:best/v1/assets/na/seo-website/accessories-transmitter.jpg"
  }, React.createElement(_components.p, {
    className: "c-md-p"
  }, "There are accessories designed specifically for entertainment purposes. One of the biggest issues for hearing aid users is understanding the audio from the TV. Yet thanks to modern TV transmitters, a solution is now at hand. These transmitters stream audio directly from televisions to the hearing aid processors, allowing you to catch every joke in your favorite comedies or every quote in the breaking news.")), "\n", React.createElement(_components.p, {
    className: "c-md-p"
  }, "As usual, there’s a wide range of options to choose from. For example, Signia have come up with their own TV transmitter which connects to their EasyTek system (an item that should be purchased separately as well). ReSound has also created their own version, the TV Streamer 2, which delivers clear audio up to 22 feet away from a screen and can be used with music systems and computers, just as effectively. Whenever an audio enhancement is required, these transmitters do the job."), "\n", React.createElement(LandingPageCta, {
    copy: "Get the best hearing aid now",
    classes: "c-cta--content"
  }), "\n", React.createElement(_components.h2, {
    className: "c-md-h2",
    id: "boost-your-hearing-aids-with-these-advanced-chargers",
    style: {
      position: "relative"
    }
  }, React.createElement(_components.a, {
    href: "#boost-your-hearing-aids-with-these-advanced-chargers",
    "aria-label": "boost your hearing aids with these advanced chargers permalink",
    className: "anchor before"
  }, React.createElement(_components.div, {
    dangerouslySetInnerHTML: {
      __html: "<svg aria-hidden=\"true\" focusable=\"false\" height=\"16\" version=\"1.1\" viewBox=\"0 0 16 16\" width=\"16\"><path fill-rule=\"evenodd\" d=\"M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z\"></path></svg>"
    }
  })), "Boost your hearing aids with these advanced chargers"), "\n", React.createElement(SideBySide, {
    imgSrc: "https://res.cloudinary.com/dhqvlsb3l/image/upload/f_auto,q_auto:best/v1/assets/na/seo-website/accessories-advanced-charger.jpg"
  }, React.createElement(_components.p, {
    className: "c-md-p"
  }, "Finally, our selection of accessories includes ways to charge your hearing aids effectively. Nobody wants to run out of battery in a critical moment, so it makes sense to choose the right power option for your device (assuming you have invested in a ", React.createElement(_components.a, {
    href: "/hearing-aids/technology/rechargeable/",
    className: "c-md-a"
  }, "rechargeable hearing aid"), " instead of battery-operated versions).\n", React.createElement(_components.br), "\n", React.createElement(_components.br), "\n", "Different manufacturers offer their own solutions. For instance, Signia’s hearing aids use the eCharger, which can be purchased separately from hear.com if you require a replacement. It also dehumidifies hearing aids overnight, helping to keep the quality and make them comfortable for use.")), "\n", React.createElement(_components.p, {
    className: "c-md-p"
  }, "And if you’re an Oticon fan, the company has come up with a Rechargeable Kit ", React.createElement(_components.a, {
    href: "/hearing-aids/brands/oticon/",
    className: "c-md-a"
  }, "for their Opn line of hearing aids"), ", which exchanges the battery compartment for an overnight charging station."), "\n", React.createElement(LandingPageCta, {
    copy: "Get the best hearing aid now",
    classes: "c-cta--content"
  }), "\n", React.createElement(_components.h2, {
    className: "c-md-h2",
    id: "from-cerumen-filters-to-drying-boxes",
    style: {
      position: "relative"
    }
  }, React.createElement(_components.a, {
    href: "#from-cerumen-filters-to-drying-boxes",
    "aria-label": "from cerumen filters to drying boxes permalink",
    className: "anchor before"
  }, React.createElement(_components.div, {
    dangerouslySetInnerHTML: {
      __html: "<svg aria-hidden=\"true\" focusable=\"false\" height=\"16\" version=\"1.1\" viewBox=\"0 0 16 16\" width=\"16\"><path fill-rule=\"evenodd\" d=\"M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z\"></path></svg>"
    }
  })), "From cerumen filters to drying boxes"), "\n", React.createElement(_components.p, {
    className: "c-md-p"
  }, "We love to accessorize our iPhones, Androids, and other gadgets – the same applies to hearing aids. With regard to care, there is a large selection of products that can keep your devices clean and in good working order. From a complete set to individual accessories, the right maintenance supplies are available for every wearer. There are, for example, special removal brushes and hooks to remove ear wax, making accurate and careful cleaning possible. The auditory canal stick can be used to remove any dirt or debris from the hearing aid. A cerumen filter allows you to control the degree of cerumen and dirt that can potentially clog your devices. This is particularly important because dirt can block the hearing aid’s audio emission and damage it as a result. Should your own hearing aids be exposed to moisture (e.g. condensation or perspiration), we generally would not recommend improper drying – the technology is too sensitive. A drying box should be used instead."), "\n", React.createElement(_components.h3, {
    className: "c-md-h3",
    id: "choose-the-right-accessories-to-optimize-your-hearing-experience",
    style: {
      position: "relative"
    }
  }, React.createElement(_components.a, {
    href: "#choose-the-right-accessories-to-optimize-your-hearing-experience",
    "aria-label": "choose the right accessories to optimize your hearing experience permalink",
    className: "anchor before"
  }, React.createElement(_components.div, {
    dangerouslySetInnerHTML: {
      __html: "<svg aria-hidden=\"true\" focusable=\"false\" height=\"16\" version=\"1.1\" viewBox=\"0 0 16 16\" width=\"16\"><path fill-rule=\"evenodd\" d=\"M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z\"></path></svg>"
    }
  })), "Choose the right accessories to optimize your hearing experience"), "\n", React.createElement(_components.p, {
    className: "c-md-p"
  }, "All of these accessories can be purchased via hear.com, allowing customers to enhance their hearing as much as possible. Whether you need to keep up with the songs in your playlists, charge your device conveniently or stream phone conversations, there’s an accessory for you."), "\n", React.createElement(SideBySide, {
    imgSrc: "https://res.cloudinary.com/dhqvlsb3l/image/upload/v1673278563/assets/na/seo-website/Dr-Emily-Smith.png"
  }, React.createElement(_components.p, {
    className: "c-md-p"
  }, React.createElement(_components.em, null, "“Hearing aid maintenance is critical and can extend the life of your hearing devices. Your provider will explain how to keep your devices clean and it’s essential to follow these instructions. This will prevent you from being frustrated by your devices and allow you to have a consistent and predictable sound experience. Often times, patients stop wearing hearing aids because they believe they have stopped working when they only need basic cleaning to function normally.“ – ", React.createElement(_components.strong, null, "Emily Smith, Au.D., Lead Audiologis")))), "\n", React.createElement(_components.h2, {
    className: "c-md-h2",
    id: "resources",
    style: {
      position: "relative"
    }
  }, React.createElement(_components.a, {
    href: "#resources",
    "aria-label": "resources permalink",
    className: "anchor before"
  }, React.createElement(_components.div, {
    dangerouslySetInnerHTML: {
      __html: "<svg aria-hidden=\"true\" focusable=\"false\" height=\"16\" version=\"1.1\" viewBox=\"0 0 16 16\" width=\"16\"><path fill-rule=\"evenodd\" d=\"M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z\"></path></svg>"
    }
  })), "Resources"), "\n", React.createElement(_components.p, {
    className: "c-md-p"
  }, "Learn everything you need to know about hearing aids and hearing loss."), "\n", React.createElement(ThreeColCta, {
    ImgSrc1: "https://res.cloudinary.com/dhqvlsb3l/image/upload/f_auto,q_auto:best/v1/assets/na/seo-website/women-with-tablet.jpg",
    ContentTitle1: "Hearing Test",
    ContentCopy1: "Check how is your hearing in less than 5 minutes.",
    Url1: "/hearing-test/",
    ImgSrc2: "https://res.cloudinary.com/dhqvlsb3l/image/upload/f_auto,q_auto:best/v1/assets/na/seo-website/hearing-aids-behind-ear-blur.jpg",
    ContentTitle2: "Value & Price",
    ContentCopy2: "Are hearing aids worth the cost? We asked an expert.",
    Url2: "/hearing-aids/prices/",
    ImgSrc3: "https://res.cloudinary.com/dhqvlsb3l/image/upload/f_auto,q_auto:best/v1/assets/na/seo-website/go-ax-grey-blur.jpg",
    ContentTitle3: "Hearing Aids",
    ContentCopy3: "Connect you to the world around you with our wide range of devices.",
    Url3: "/resources/hearing-aids/"
  }));
}
function MDXContent(props = {}) {
  const {wrapper: MDXLayout} = Object.assign({}, _provideComponents(), props.components);
  return MDXLayout ? React.createElement(MDXLayout, props, React.createElement(_createMdxContent, props)) : _createMdxContent(props);
}
export default MDXContent;
function _missingMdxReference(id, component) {
  throw new Error("Expected " + (component ? "component" : "object") + " `" + id + "` to be defined: you likely forgot to import, pass, or provide it.");
}
